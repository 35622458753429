<template>
  <div class="login-container">

     <!-- Right side - Background with Logo -->
     <div class="brand-side none_layout" >
      <div class="brand-content">
        <div class="logo-container">
          <div class="brand-name">
            <div class="logo-wrapper">
              <img src="@/assets/logo.png" alt="Logo" class="brand-logo">
            </div>
          </div>
        </div>
        <div class="brand-description">
          <h2>ברוכים הבאים</h2>
          <p>מערכת שיחות זיהוי חסוי</p>
        </div>
      </div>
      <div class="background-pattern"></div>
    </div>

    <!-- Left side - Login Form -->
    <div class="login-form-side">
      <div class="login-card" :class="{ 'on-hover': hover }" @mouseover="hover = true" @mouseleave="hover = false">
        <div class="card-content">
          <div class="logo-container-mobile">
            <img src="@/assets/logo.png" alt="Logo" class="logo-mobile" :style="{ width: isResponsive ? '80px' : '100px' }">
          </div>

          <h1 class="title">
            התחברות
            <div class="subtitle">ברוכים הבאים למערכת</div>
          </h1>

          <form @submit.prevent="login" ref="form" class="login-form">
            <div class="form-group">
              <label for="phone">טלפון</label>
              <div class="input-wrapper">
                <i class="mdi mdi-phone icon"></i>
                <input 
                  type="tel" 
                  id="phone" 
                  v-model="phone" 
                  placeholder="הזן מספר טלפון" 
                  required
                  :disabled="loading"
                  required
                >
              </div>
            </div>

            <div class="form-group">
              <label for="password">סיסמה</label>
              <div class="input-wrapper">
                <i class="mdi mdi-lock icon"></i>
                <input 
                  :type="showPassword ? 'text' : 'password'" 
                  id="password" 
                  v-model="password" 
                  placeholder="הזן סיסמה" 
                  required
                  :disabled="loading"
                  required
                >
                <i 
                  class="mdi password-toggle" 
                  :class="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click="showPassword = !showPassword"
                ></i>
              </div>
            </div>

            <div class="forgot-password">
              <a href="#" @click.prevent="forgotPassword">שכחת סיסמה?</a>
            </div>

            <button 
              type="submit" 
              class="login-button" 
              :disabled="loading"
            >
              <i class="mdi mdi-login"></i>
              <span>כניסה למערכת</span>
              <div v-if="loading" class="loader"></div>
            </button>
          </form>

          <!-- Support Section -->
          <div class="support-links">
            <a href="https://example.com" target="_blank" class="support-link">
              <i class="mdi mdi-help-circle"></i>
              תמיכה
            </a>
            <a href="https://example.com/contact" target="_blank" class="support-link">
              <i class="mdi mdi-phone"></i>
              צור קשר
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar for notifications -->
    <div class="snackbar" v-if="snackbar" :class="snackbarColor">
      <i class="mdi" :class="snackbarIcon"></i>
      <span>{{ snackbarText }}</span>
      <button @click="snackbar = false" class="close-button">סגור</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isResponsive: false,
      hover: false,
      phone: "",
      password: "",
      showPassword: false,
      loading: false,
      snackbar: false,
      snackbarColor: "success",
      snackbarText: "",
    };
  },
  computed: {
    snackbarIcon() {
      return this.snackbarColor === 'success' ? 'mdi-check-circle' : 'mdi-alert-circle';
    }
  },
  methods: {
    async login() {
        this.loading = true
        let api = process.env.VUE_APP_HISTORY_URL;

        var formdata = new FormData();
        formdata.append("clid", this.phone);
        formdata.append("auth_pass", this.password);
        formdata.append("api_key", process.env.VUE_APP_API_KEY);

        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };

        fetch(api, requestOptions)
          .then(response => {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              return response.json();
            } else {
              return response.text();
            }
          }).then(result => {
            // console.log(result);
             if (result.includes("username or password inccorect")) {
              this.showSnackBar("שגיאת התחברות, שם משתמש או סיסמה לא תקינים", "red");
              this.loading = false;
            } else {
              this.$router.push({ name: 'calls', params: { phone: this.phone , password: this.password, calls: result } })
              this.loading = false;

            }
          }).catch(error => {
            console.log('error', error)
          });
    },
    forgotPassword() {
      // this.showSnackBar("קישור לאיפוס סיסמה נשלח לדוא״ל שלך", "success");
    },
    showSnackBar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color;
      this.snackbar = true;
      
      // Auto hide after 4 seconds
      setTimeout(() => {
        this.snackbar = false;
      }, 4000);
    },
    handleResize() {
      this.isResponsive = window.innerWidth <= 768;
    }
  },
  mounted() {
    document.title = 'התחברות | מערכת ניהול';
    this.isResponsive = window.innerWidth <= 768;
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.login-container {
  display: flex;
  min-height: 100vh;
  direction: rtl;
  font-family: 'Rubik', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Left side - Login Form */
.login-form-side {
  width: 50%;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.login-card {
  background: #ffffff;
  border-radius: 24px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 450px;
}

.login-card.on-hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.12);
}

.card-content {
  padding: 40px 30px;
}

.logo-container-mobile {
  display: none;
  text-align: center;
  margin-bottom: 20px;
}

.logo-mobile {
  max-width: 100px;
  transition: all 0.3s ease;
}

.title {
  color: #1e517b;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

.subtitle {
  font-size: 18px;
  font-weight: 400;
  color: #2c3e50;
  margin-top: 8px;
}

.login-form {
  margin-top: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #2c3e50;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f5f7fa;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid #e0e7ff;
}

.input-wrapper:focus-within {
  border-color: #1e517b;
  box-shadow: 0 0 0 2px rgba(30, 81, 123, 0.2);
  transform: translateX(-5px);
}

.input-wrapper .icon {
  color: #1e517b;
  font-size: 20px;
  margin: 0 12px;
}

.input-wrapper input {
  flex: 1;
  padding: 12px 15px;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #2c3e50;
  outline: none;
  width: 100%;
}

.input-wrapper input::placeholder {
  color: #a0aec0;
}

.password-toggle {
  cursor: pointer;
  color: #718096;
  padding: 0 12px;
  font-size: 20px;
}

.forgot-password {
  text-align: left;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #1e517b;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s;
}

.forgot-password a:hover {
  color: #f39c12;
  text-decoration: underline;
}

.login-button {
  width: 100%;
  padding: 14px 20px;
  background: linear-gradient(45deg, #1e517b 30%, #2c3e50 90%);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  height: 50px;
}

.login-button:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 20px rgba(30, 81, 123, 0.4);
}

.login-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.login-button i {
  margin-left: 8px;
  font-size: 20px;
}

.support-links {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.support-link {
  display: flex;
  align-items: center;
  color: #718096;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;
  transition: color 0.3s ease;
}

.support-link:hover {
  color: #1e517b;
}

.support-link i {
  margin-left: 5px;
}

/* Right side - Brand */
.brand-side {
  width: 50%;
  background: linear-gradient(135deg, #1e517b 0%, #2c3e50 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  color: white;
}

.brand-content {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 40px;
}

.logo-container {
  margin-bottom: 40px;
}

.brand-name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-wrapper {
  background: transparent;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: inline-block;
  position: relative;
}

.brand-logo {
  max-width: 200px;
  height: auto;
  filter: drop-shadow(0 0 70px rgba(255, 255, 255, 1)) 
          drop-shadow(0 0 40px rgba(255, 255, 255, 0.98)) 
          drop-shadow(0 0 20px rgba(255, 255, 255, 0.95)); /* Triple-layered white glow */
  transition: all 0.5s ease;
  position: relative;
  z-index: 2;
}

.logo-wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  border-radius: 50%;
  z-index: 1;
  transform: scale(1.2);
}

.brand-text {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
}

.brand-highlight {
  color: #f39c12;
}

.brand-tagline {
  font-size: 18px;
  margin-top: 10px;
  opacity: 0.9;
}

.brand-description {
  margin-top: 60px;
}

.brand-description h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.brand-description p {
  font-size: 18px;
  opacity: 0.8;
}

.background-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at 20% 30%, rgba(255, 255, 255, 0.1) 0%, transparent 8%),
                    radial-gradient(circle at 80% 70%, rgba(255, 255, 255, 0.1) 0%, transparent 8%);
  background-size: 30px 30px;
  opacity: 0.5;
}

/* Snackbar */
.snackbar {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 20px;
  border-radius: 50px;
  color: white;
  display: flex;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: slideDown 0.3s ease-out;
}

.snackbar.success {
  background-color: #10b981;
}

.snackbar.error {
  background-color: #ef4444;
}

.snackbar i {
  margin-left: 8px;
  font-size: 18px;
}

.close-button {
  background: none;
  border: none;
  color: white;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  opacity: 0.8;
  transition: opacity 0.3s;
  margin-right: 0;
  margin-left: 15px;
}

.close-button:hover {
  opacity: 1;
}

@keyframes slideDown {
  from {
    transform: translate(-50%, -20px);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.8s linear infinite;
  position: absolute;
  right: 15px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .none_layout {
    display: none;
  }

  .login-container {
    flex-direction: column;
  }
  
  .login-form-side, .brand-side {
    width: 100%;
  }
  
  .login-form-side {
    order: 2;
    padding: 20px;
  }
  
  .brand-side {
    order: 1;
    min-height: 200px;
  }
  
  .brand-content {
    padding: 20px;
  }
  
  .brand-text {
    font-size: 36px;
  }
  
  .brand-tagline {
    font-size: 16px;
  }
  
  .brand-description {
    margin-top: 20px;
    display: none;
  }
  
  .logo-container-mobile {
    display: block;
  }
  
  .card-content {
    padding: 30px 20px;
  }
  
  .title {
    font-size: 24px;
  }
  
  .subtitle {
    font-size: 16px;
  }
  
  .login-button {
    padding: 12px 16px;
  }
  
  .brand-logo {
    max-width: 140px;
  }
}

</style>