<template>
  <div class="sysbody">
    <FormLogin/>
  </div>
</template>
<script>
import FormLogin from '@/components/FormLogin.vue'
export default {
  name: 'login',
  components: {
    FormLogin
  },
  mounted() {
    document.body.style.overflow = "hidden"; // Hides vertical scrolling
    document.documentElement.style.overflow = "hidden"; // Hides for the entire document
  }
}
</script>
